<template>
  <div class="loading">

    <a-spin />
  </div>
</template>

<script>
export default {

}

</script>

<style>
.loading{
    width:100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>
